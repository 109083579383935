import React,{Fragment, Component} from 'react';
import {Link} from 'react-router-dom';
import Header from '../Layout/Header'; 
import Footer from '../Layout/Footer'; 
import PageTitle from '../Layout/PageTitle';
import {LightGallery} from './ClassesDetail';
//images
import bnr1 from './../../images/line2.png';
import large1 from './../../images/blog/large/pic3.jpg';
import large2 from './../../images/blog/large/pic4.jpg';
import recent2 from './../../images/blog/recent-blog/pic2.jpg';
import recent3 from './../../images/blog/recent-blog/pic3.jpg';

class EventDetail extends Component{
	render(){
		return(
			<Fragment>
			<Header />	
			<div className="page-content">
					<PageTitle motherMenu="News Letter" activeMenu="News Letter" />
					<div className="content-block">
						<div className="section-full bg-white content-inner" style={{backgroundImage:"url(" + bnr1 + ")", backgroundSize:"contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<div className="class-media text-center">
											<img src={large1} alt="" />
											<img src={large2} alt="" />
										</div>
									</div>
									
								</div>
							</div>
						</div>
					</div>
			</div>
			<Footer />
			</Fragment>
			
		)
	}
}
export default EventDetail;