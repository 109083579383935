import React,{Fragment, Component} from 'react';
import {Link} from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import BannerSlider from '../Element/BannerSlider';
import FromSlider from '../Element/FromSlider';
import TestiMonialSlider from '../Element/TestiMonialSlider';
import TeacherSlider from '../Element/TeacherSlider';
import GallerySlider from '../Element/GallerySlider';

import icon1 from './../../images/icon/icon1.jpg';
import icon2 from './../../images/icon/icon2.jpg';
import icon3 from './../../images/icon/icon3.jpg';
import icon4 from './../../images/icon/icon4.jpg';
import bgimg1 from './../../images/line.png';
import bnr3 from './../../images/background/bg6.jpg';
import bgimg3 from './../../images/line2.png';

const iconBlog = [
	{ image: icon1 , title1: 'To Think Creatively', title2: 'and Create' },	
	{ image: icon2 , title1: 'To Feel Fne and to', title2:'Understand Emotions' },	
	{ image: icon3 , title1: 'To be Independent', title2: 'and Active' },	
	{ image: icon4 , title1: 'To Apply', title2:'Knowledge in Life' },	
];
const iconBlog2 = [
	{ icon: <i className="flaticon-rattle text-blue" />, title: 'Daycare', desc: "We provide daycare for children between 1.5 years to 6 years of age. ", },	
	// { icon: <i className="flaticon-bricks text-green" />,title: 'EYFS', desc: "EYFS has been a great source for the wholesome development of children from 1 year to 6 years of age." },	
	{ icon: <i className="flaticon-puzzle text-orange" />, title: 'Safe and Hygienic', desc: "Child care aims to provide safe and hygienic care for children in a home-like environment."  },	
];


class Index1 extends Component{
	render(){
		return(
			<Fragment>
				<Header />
				<div className="page-content bg-white">
					{ /* Slider Banner */ }
						<BannerSlider />
					{ /* Slider Banner */ }
					<div className="content-block">
						{/*  About Us */}
							<div className="section-full bg-white content-inner-1 text-center">
								<div className="container">
									<div className="section-head">
									<h2 className="head-title text-secondry" style={{ color:"#3D1766"}}>Welcome to Ajoy's Child Care</h2>
										<p>A Place for joyful learning!</p>
									</div>
									{/* <div className="row">
										{iconBlog.map((data, index)=>(
											<div className="col-lg-3 col-md-6 col-sm-6 col-12" key={index}>
												<div className="icon-bx-wraper sr-iconbox m-b20">
													<div className="icon-lg m-b20">
														<Link to={"#"} className="icon-cell"><img src={data.image} alt=""/></Link>
													</div>
													<div className="icon-content">
														<h6 className="dlab-tilte">{data.title1}<br/>{data.title2}</h6>
													</div>
												</div>
											</div>
										))}
									</div> */}
								</div>
							</div>
						{/*  About Us End*/}
						<div className="section-full bg-white content-inner-2 about-box" style={{backgroundImage:"url(" + bgimg1 +")",  backgroundSize:"contain",backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
							<div className="container">
								<div className="row">
									<div className="col-lg-7 col-md-12 col-sm-12 col-12">
										<div className="section-head">
											<h2 className="head-title text-secondry" style={{ color: "#3D1766", fontSize:"36px" }}>"It is through children's early childhood experiences, when the brain is rapidly developing, that we have the opportunity to support learning through a child's natural curiosity and imaginative play"</h2>
											<p>
												Here the child learns about the world around him. Art and craft provoke his imagination and develops his fine motor skills. We also have music and movement which helps in developing the coordination of different muscles as well as his gross motor skills.
											</p>
											<p>We motivate children to develop different skills through their natural curiosity and imagination.</p>
											{/* <Link to={"./faqs"} className="btn btn-md kids-btn radius-xl">Learn more</Link> */}
										</div>
									</div>
									<div className="col-lg-5 col-md-12 col-sm-12 col-12">
										{iconBlog2.map((item, index)=>(
											<div className="icon-bx-wraper left" key={index}>
												<div className="icon-lg m-b20"> <span className="icon-cell">{item.icon}</span> </div>
												<div className="icon-content">
													<h2 className="dlab-tilte">{item.title}</h2>
													<p>{item.desc}</p>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
						
						<div className="section-full bg-white content-inner-2 about-content bg-img-fix" style={{ backgroundImage: "url(" + bnr3 + ")" }}>
							<BackgroundBlog2 />
						</div>
						<div className="section-full bg-white content-inner-1">
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry" style={{ color: "#3D1766" }}>Gallery</h2>
									{/* <p>We provide three classes with nine to twenty children each aged twelve months to six years of age.</p> */}
								</div>
								<GallerySlider />
							</div>
						</div>
						{/* <div className="section-full bg-white content-inner-1" style={{backgroundImage:"url("+ bgimg3 +")", backgroundSize:"contain", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry">About the Teachers</h2>
									<p>We have an excellent teacher to child ratio at our Kindergarten to ensure that each child receives the attention he or she needs</p>
								</div>
								<TeacherSlider />
							</div>
						</div> */}
						<div className="section-full bg-white content-inner-1">
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry" style={{ color: "#3D1766" }}>Testimonials</h2>
									{/* <p>We have an excellent teacher to child ratio at our Kindergarten to ensure that each child receives the attention he or she needs</p> */}
								</div>
								<TestiMonialSlider />
							</div>
						</div>
						{/* <div className="section-full bg-white content-inner">
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry">From the Blog</h2>
									<p>We have an excellent teacher to child ratio at our Kindergarten to ensure that each child receives the attention he or she needs</p>
								</div>
								<FromSlider />
							</div>
						</div> */}
					</div>
				</div>
				<Footer />
			</Fragment>
		)
	}
}
function BackgroundBlog2() {
	return (
		<>
			<div className="container">
				<div className="row">
					<div className="col-lg-12 col-md-12 col-sm-12 col-12  about-text" style={{ visibility: "visible", animationDuration: "2s", animationDelay: "0.2s", animationName: "fadeIn" }}>
						<div className="section-head text-center">
							<h4 className="text-white"></h4>
							<h2>Schedule a Tour or Call for Enquires <br /><span className="text-yellow">+91 7022380359</span></h2>
							{/* <Link to={"./contect-us"} className="btn btn-md radius-xl">Read More</Link> */}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export { BackgroundBlog2 };
export default Index1;