import React,{Fragment, Component} from 'react';
import {Link} from 'react-router-dom'; 
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import PageTitle from '../Layout/PageTitle';
import Masonry from 'react-masonry-component';
import SimpleReactLightbox from 'simple-react-lightbox';
import {SRLWrapper, useLightbox} from 'simple-react-lightbox'; 
import AccordionBlog from '../Element/AccordionBlog';
import TestiMonialSlider from '../Element/TestiMonialSlider';
import VideoPopup from '../Element/VideoPopup';
//Images
import bnr1 from './../../images/line2.png';
import bnr2 from './../../images/background/bg5.jpg';
import bgimg2 from './../../images/background/bg7.jpg';
import bnr4 from './../../images/line2.png';
import about from './../../images/about/about-3.jpg';
import gallery1 from './../../images/gallery/pic1.jpg';
import gallery2 from './../../images/gallery/pic2.jpg';
import gallery3 from './../../images/gallery/pic3.jpg';
import gallery4 from './../../images/gallery/pic4.jpg';
import gallery5 from './../../images/gallery/pic5.jpg';
import gallery6 from './../../images/gallery/pic6.jpg';

// Masonry section
const masonryOptions = {                                          
    transitionDuration: 0
};
const imagesLoadedOptions = { background: '.my-bg-image-el' }
// Masonry section end

//Light Gallery Icon
const Iconimage = props => {
	const { openLightbox } = useLightbox()
	return (
		<Link to={"#"}
			onClick={(e) => {
				e.preventDefault()
				openLightbox(props.imageToOpen)
			}} className="check-km" >
			<i className="fa fa-search icon-bx-xs"></i>
		</Link>
	)

}
const galleryBlog = [
	{image: gallery1},	
	{image: gallery2},	
	{image: gallery3},	
	{image: gallery4},	
	{image: gallery5},	
	{image: gallery6},	
];

function BackgroundBlog(){
	return(
		<>
			<div className="container">
				<div className="row">
					<div className="col-lg-12 col-md-12 col-sm-12 col-12  about-text"  style={{visibility: "visible", animationDuration: "2s", animationDelay: "0.2s", animationName: "fadeIn"}}>
						<div className="section-head text-center">
							<div className="video-play">
								{/* <VideoPopup /> */}
							</div>
							<h2>Let Your Kids Have an Amazing<br/>Time at Ajoy's Child Care</h2>
							{/* <Link to={"./contect-us"} className="btn btn-md radius-xl">Read More</Link> */}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
function EducationBanner() {
	return (
		<>
			<div className="section-full bg-white content-inner-2 about-content bg-img-fix" style={{ backgroundImage: "url(" + bgimg2 + ")" }}>
				<div className="about-overlay-box"></div>
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="section-head">
								{/* <h2 className="head-title text-yellow">Education from birth<br />begins with us</h2>
								<p className="text-white">Our Ajoy's Child Care, together with one of the oldest private schools, created a joint school preparation project. The goal of the project is the harmonious development, socializationfor admission to the best educational institutions of our city.</p> */}
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-12 col-12"></div>
					</div>
				</div>
			</div>
		</>
	)
}

class AboutUs1 extends Component{
	componentDidMount(){
		var splitBox = document.querySelectorAll('.split-box');
       
		var fSB = [].slice.call(splitBox);
		
		fSB.forEach(el => el.classList.add('split-active'));
	}
	render(){
		return(
			<Fragment>
				<Header />	
				 <div className="page-content">
					<PageTitle  motherMenu="About Us"  activeMenu="About Us" />
					<div className="content-block">
						<div className="section-full bg-white content-inner-2 about-area" style={{backgroundImage:"url("+ bnr1 +")", backgroundSize:"contain", backgroundRepeat: "no-repeat",backgroundPosition: "center"}}>
							<div className="container">
								<div className="row">
									<div className="col-lg-6 col-md-12 col-sm-12 m-b15">
										<div className="section-head">
											<h2 className="head-title text-secondry" style={{ color: "#3D1766" }}>About the founder</h2>
											<p>I am Shruthi. I love children and am passionate about early years education. I have worked with children for more than 10 years and am the happiest when working with them.</p>
											
											<p>The problems faced by working parents to find a safe and secure place for their child’s care have always been of concern. The idea of providing a space for children where holistic development happens has been the main contributing factor to start my venture in child care. I wanted to provide a safe and secure place, where children would learn through play and develop a love for learning, which would help them throughout their lives.</p>

											<h2 className="head-title text-secondry" style={{ color: "#3D1766" }}>Importance of Daycare</h2>
											<p>In a daycare, the child's physical, social, and Emotional development happens in a play way setting, seamlessly and enjoyably.</p>
											
											<p>Here the child learns to follow instructions and share his toys with other children. He also learns to wait for his turn and communicate his needs. he follows a schedule for activities,  playtime, feed time, and nap time.</p>
											
											<p>The child also learns about the world around him. Art and craft provoke his imagination and develops his fine motor skills. We also have music and movement which helps in developing the coordination of different muscles as well as his gross motor skills.</p>
											<p>In our center, we plan activities that focus on the 7 areas of development The 7 areas are:
											
											<ul>
													<li> Literacy</li>
													<li> Communication and language</li>
													<li> Math</li>
													<li> Understanding the world (Science)</li>
													<li> Physical </li>
													<li> Art </li>
													<li> PSED (Personal  Social Emotional Development)</li>
											</ul>
											</p>
											
										</div>
										{/* <AccordionBlog /> */}
									</div>
									<div className="col-lg-6 col-md-12 col-sm-12 teacher-content">
										<div className="split-box">
											<div className="about-media">
												<img src={about} alt=""/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="section-full bg-white content-inner-2 about-content bg-img-fix" style={{backgroundImage:"url(" + bnr2 + ")"}}>
							<BackgroundBlog />
						</div>
						{/*  Portfolio  */}
						<div className="section-full content-inner">
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry" style={{ color: "#3D1766" }}>Gallery</h2>
									{/* <p>We provide three classes with nine to twenty children each aged twelve months to six years of age.</p> */}
								</div>
								<SimpleReactLightbox>
									<SRLWrapper>
										<div className="clearfix" id="lightgallery">
											<ul id="masonry" className="dlab-gallery-listing gallery-grid-4 gallery">
												<Masonry
													className={'my-gallery-class'} 
													options={masonryOptions} 
													disableImagesLoaded={false} 
													updateOnEachImageLoad={false} 
													imagesLoadedOptions={imagesLoadedOptions} // default {}
												>
													{galleryBlog.map((data,index)=>(
														<li className="web design card-container col-lg-4 col-md-6 col-sm-6 "  key={index}>
															<div className="dlab-box frame-box m-b30">
																<div className="dlab-thum dlab-img-overlay1"> 
																	<img src={data.image} alt="" />
																	<div className="overlay-bx">
																		<div className="overlay-icon"> 
																			<Iconimage imageToOpen={index} /> 
																		</div>
																	</div>
																</div>
															</div>
														</li>
													))}
												</Masonry>		
											</ul>
										</div>
									</SRLWrapper>	
								</SimpleReactLightbox>	
							</div>
						</div>
						<div className='text-center'>
							<img src={bgimg2} alt='images'  />
						</div>
						{/* <EducationBanner /> */}
						{/* <div className="section-full bg-white content-inner-2" style={{backgroundImage:"url(" + bnr4 +")", backgroundSize:"contain",backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry">Testimonials about center</h2>
									<p>We have an excellent teacher to child ratio at our Kindergarten to ensure that each child receives the attention he or she needs</p>
								</div>
								<TestiMonialSlider />
							</div>
						</div> */}
					</div>
				</div>	
				<Footer />	
			</Fragment>
		)
	}
}
export { BackgroundBlog, EducationBanner };
export default AboutUs1;