import React,{Fragment, Component} from 'react';
import {Link} from 'react-router-dom';
import Header from '../Layout/Header'; 
import Footer from '../Layout/Footer'; 
import PageTitle from '../Layout/PageTitle';
//Images
import large1 from './../../images/blog/large/pic1.jpg';
import large2 from './../../images/blog/large/pic2.jpg';
import large3 from './../../images/blog/large/pic3.jpg';

const eventBlog = [
	{ images: large1, date: '14', month:'Jan', title: 'Makar Sankranti',},	
	{ images: large2, date: '26', month: 'Jan', title: 'Republic Day',},	
	{ images: large3, date: '14', month: 'Feb', title: 'Valentines Day',},	
	{ images: large1, date: '20', month: 'Feb', title: 'Love your PetDay',},	
	{ images: large2, date: '8', month: 'March', title: 'Womens Day',},	
	{ images: large3, date: '8', month: 'March', title: 'Holi', },	
	{ images: large3, date: '10', month: 'April', title: 'National Siblings Day', },
	{ images: large3, date: '22', month: 'April', title: 'Earth Day', },
	{ images: large3, date: '1', month: 'May', title: 'Labour Day', },
	{ images: large3, date: '14', month: 'May', title: 'Mothers Day', },
	{ images: large3, date: '18', month: 'June', title: 'Fathers Day', },
	{ images: large3, date: '21', month: 'June', title: 'Yoga Day', },
	{ images: large3, date: '6', month: 'August', title: 'Friendship Day', },
	{ images: large3, date: '15', month: 'August', title: 'Independence Day', },
	{ images: large3, date: '5', month: 'Sept', title: 'Teachers Day', },
	{ images: large3, date: '5', month: 'Sept', title: 'International Day of Chess', },
	{ images: large3, date: '2', month: 'Oct', title: 'Gandhi Jayanti', },
	{ images: large3, date: '31', month: 'Oct', title: 'Halloween', },
	{ images: large3, date: '12', month: 'Nov', title: 'Diwali', },
	{ images: large3, date: '14', month: 'Nov', title: 'Childrens Day', },
	{ images: large3, date: '25', month: 'Dec', title: 'Christmas Day', },
];

class Event extends Component{
	render(){
		return(
			<Fragment>
			<Header />	
			<div className="page-content">
					<PageTitle motherMenu="Special Days" activeMenu="Special Days" />
				<div className="content-block">
					<div className="section-full bg-white content-inner">
						<div className="container">
							<div className="row">
								{eventBlog.map((item, index)=>(
									<div className="col-lg-6 col-md-6 col-sm-6" key={index}>
										<div className="event-box">
											<div className="event-media">
												<img src={""} alt=""/>
											</div>
											<div className="event-info">
												<div className="dlab-post-title">
													<h3 className="post-title mt-2"><Link to={"/event-details"}>{item.title}</Link></h3>
												</div>
												<div className="event-meta">
													<ul>
														<li className="post-date mt-4"><strong>{item.date}</strong><span>{item.month}</span></li>
														{/* <li className="post-author"><i className="fa fa-map-marker"></i> 89 Newyork City.</li> */}
													</ul>
												</div>
												{/* <div className="dlab-post-text">
												   <p>Lorem ipsum dolor sit amet elit. Cum veritatis sequi nulla nihil.</p>
												</div> */}
											</div>
										</div>
									</div>
								))}
							</div>
							{/* <div className="row">
								<div className="col-lg-12 col-md-12 col-sm-12">
									<div className="pagination-bx rounded pagination-lg clearfix primary">
										<ul className="pagination justify-content-center">
											<li className="previous"><Link to={'#'}><i className="ti-arrow-left"></i> Prev</Link></li>
											<li className="active"><Link to={'#'}>1</Link></li>
											<li><Link to={'#'}>2</Link></li>
											<li><Link to={'#'}>3</Link></li>
											<li className="next"><Link to={'#'}>Next <i className="ti-arrow-right"></i></Link></li>
										</ul>
									</div>
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</div>
			<Footer />
			</Fragment>
		)
	}
}
export default Event;