import React,{Fragment, Component} from 'react';
import {Link} from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import PageTitle from '../Layout/PageTitle';
//images
import bnr1 from './../../images/line2.png';
import clsimg1 from './../../images/classes/pic7.jpg';
import clsimg2 from './../../images/classes/pic8.jpg';
import clsimg3 from './../../images/classes/pic9.jpg';
import clsimg4 from './../../images/classes/pic10.jpg';
import clsimg5 from './../../images/classes/pic11.jpg';


const classesBlog =[
	{images: clsimg4, title: 'Day Care', age:"1.5-6"},	
	{images: clsimg2, title: 'Toddlers', age:"1.5-2.5" },	
	{images: clsimg1, title: 'Pre-Schoolers', age: "4-6" },	
	{ images: clsimg5, title: 'After-Schoolers', age: "4-6" },	
	{ images: clsimg3, title: 'Backup Care', age: "2.5-6" },
];

class Classes extends Component{
	render(){
		return(
			<Fragment>
				<Header />
				<div className="page-content">
					<PageTitle  motherMenu="Classes"  activeMenu="Classes" />
					<div className="content-block">
						<div className="section-full bg-white content-inner" style={{backgroundImage:"url("+ bnr1+")", backgroundSize:"contain",backgroundRepeat: "no-repeat",backgroundPosition: "center"}}>
							<div className="container">
								<div className="row sp40">
									{classesBlog.map((data, index)=>(
										<div className="col-lg-4 col-md-6 col-sm-6" key={index}>
											<div className="class-item">
												<div className="class-media"> 
													<img src={data.images} alt=""/>
													<p>
														<span>Class Time:</span> 
														08:00 am - 06:00 pm
													</p>
												</div>
												<div className="class-info">
													<h4><Link to={"/classes-details"}>{data.title}</Link></h4>
													{/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit nulla felis ipsum.</p> */}
													<ul className="schedule">
														<li className="bg-blue class-size text-center"><span>Class Size</span> <span>0 - 15</span> </li>
														<li></li>
														<li className="bg-green years-old text-center"><span>Years Old</span> <span>{data.age}</span> </li>
														{/* <li className="bg-orange tution"><span>Tution Fee</span> <span>$1500</span> </li> */}
													</ul>
												</div>
											</div>
										</div>
									))}	
								</div>
							</div>
						</div>	
					</div>
				</div>
				<Footer />
			</Fragment>
		)
	}
}
export default Classes;